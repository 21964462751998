// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-product-details-js": () => import("/opt/build/repo/src/templates/product-details.js" /* webpackChunkName: "component---src-templates-product-details-js" */),
  "component---src-templates-index-page-js": () => import("/opt/build/repo/src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-about-page-js": () => import("/opt/build/repo/src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-accessories-page-js": () => import("/opt/build/repo/src/templates/accessories-page.js" /* webpackChunkName: "component---src-templates-accessories-page-js" */),
  "component---src-templates-blog-post-js": () => import("/opt/build/repo/src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-boot-fit-guide-page-js": () => import("/opt/build/repo/src/templates/boot-fit-guide-page.js" /* webpackChunkName: "component---src-templates-boot-fit-guide-page-js" */),
  "component---src-templates-help-page-js": () => import("/opt/build/repo/src/templates/help-page.js" /* webpackChunkName: "component---src-templates-help-page-js" */),
  "component---src-templates-kids-page-js": () => import("/opt/build/repo/src/templates/kids-page.js" /* webpackChunkName: "component---src-templates-kids-page-js" */),
  "component---src-templates-mens-page-js": () => import("/opt/build/repo/src/templates/mens-page.js" /* webpackChunkName: "component---src-templates-mens-page-js" */),
  "component---src-templates-privacy-policy-page-js": () => import("/opt/build/repo/src/templates/privacy-policy-page.js" /* webpackChunkName: "component---src-templates-privacy-policy-page-js" */),
  "component---src-templates-product-page-js": () => import("/opt/build/repo/src/templates/product-page.js" /* webpackChunkName: "component---src-templates-product-page-js" */),
  "component---src-templates-shared-content-js": () => import("/opt/build/repo/src/templates/shared-content.js" /* webpackChunkName: "component---src-templates-shared-content-js" */),
  "component---src-templates-stores-page-js": () => import("/opt/build/repo/src/templates/stores-page.js" /* webpackChunkName: "component---src-templates-stores-page-js" */),
  "component---src-templates-test-page-js": () => import("/opt/build/repo/src/templates/test-page.js" /* webpackChunkName: "component---src-templates-test-page-js" */),
  "component---src-templates-womens-page-js": () => import("/opt/build/repo/src/templates/womens-page.js" /* webpackChunkName: "component---src-templates-womens-page-js" */),
  "component---src-templates-card-balance-page-js": () => import("/opt/build/repo/src/templates/card-balance-page.js" /* webpackChunkName: "component---src-templates-card-balance-page-js" */),
  "component---src-templates-tags-js": () => import("/opt/build/repo/src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("/opt/build/repo/src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-cart-index-js": () => import("/opt/build/repo/src/pages/cart/index.js" /* webpackChunkName: "component---src-pages-cart-index-js" */),
  "component---src-pages-contact-index-js": () => import("/opt/build/repo/src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-contact-thanks-js": () => import("/opt/build/repo/src/pages/contact/thanks.js" /* webpackChunkName: "component---src-pages-contact-thanks-js" */),
  "component---src-pages-tags-index-js": () => import("/opt/build/repo/src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */)
}

